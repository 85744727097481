import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useAppNavigation from '@src/shared/lib/hooks/useAppNavigation';
import Icon from '@shared/ui/icons/Icon';
import EmptyViewInteractive from '@components/empty-views/EmptyViewInteractive';
import { ReactComponent as PortfoliosEmptyIcon } from '@icons/custom/portfolios-empty.svg';
import { ReactComponent as ExchangeIcon } from '@icons/custom/exchanges-empty.svg';
import { ExchangeSidebar, openSidebar } from '@store/slices/exchange';
import { GlobalSidebarElements, openSidebar as openSidebarAppAction } from '@store/slices/application';
const PortfoliosEmptyView = ({ className = undefined, profileType, isMyProfile, haveExchanges = undefined, }) => {
    const { t } = useTranslation();
    const { navigate, routes } = useAppNavigation();
    const dispatch = useDispatch();
    const goToMarketplace = () => {
        navigate(routes.MARKETPLACE);
    };
    const goToOverview = () => {
        navigate(routes.HOME_OVERVIEW);
    };
    const createPortfolio = () => {
        dispatch(openSidebarAppAction(GlobalSidebarElements.CreatePortfolio));
        dispatch(openSidebar(ExchangeSidebar.CreatePortfolio));
    };
    const { messageTitle, BodyImgIcon, actionButtonTitle, messageBody, onActionButtonClick, } = useMemo(() => {
        if (typeof profileType !== 'undefined') {
            if (profileType === 'TRADER') {
                if (isMyProfile && !haveExchanges) {
                    return {
                        messageTitle: t('portfolio.empty.my_trader_no_exchanges.title'),
                        messageBody: t('portfolio.empty.my_trader_no_exchanges.body'),
                        BodyImgIcon: PortfoliosEmptyIcon,
                        actionButtonTitle: t('navigation.go_to', {
                            location: t('pages.overview.overview', { ns: 'common' }),
                            ns: 'common',
                        }),
                        onActionButtonClick: goToOverview,
                    };
                }
                if (isMyProfile && haveExchanges) {
                    return {
                        messageTitle: t('portfolio.empty.my_trader_no_active_portfolios.title'),
                        messageBody: t('portfolio.empty.my_trader_no_active_portfolios.body'),
                        BodyImgIcon: PortfoliosEmptyIcon,
                        actionButtonTitle: t('portfolio.empty.my_trader_no_active_portfolios.btn_text'),
                        onActionButtonClick: createPortfolio,
                    };
                }
                return {
                    messageTitle: t('portfolio.empty.trader.title'),
                    messageBody: t('portfolio.empty.trader.body'),
                    BodyImgIcon: PortfoliosEmptyIcon,
                    actionButtonTitle: t('navigation.go_to', {
                        location: t('pages.marketplace.marketplace', { ns: 'common' }),
                        ns: 'common',
                    }),
                    onActionButtonClick: goToMarketplace,
                };
            }
            if (isMyProfile) {
                return {
                    messageTitle: t('portfolio.empty.client_my_profile.title'),
                    messageBody: t('portfolio.empty.client_my_profile.body'),
                    BodyImgIcon: ExchangeIcon,
                    actionButtonTitle: t('navigation.go_to', {
                        location: t('pages.overview.overview', { ns: 'common' }),
                        ns: 'common',
                    }),
                    onActionButtonClick: goToOverview,
                };
            }
            return {
                messageTitle: t('portfolio.empty.client.title'),
                messageBody: t('portfolio.empty.client.body'),
                BodyImgIcon: PortfoliosEmptyIcon,
                actionButtonTitle: t('navigation.go_to', {
                    location: t('pages.marketplace.marketplace', { ns: 'common' }),
                    ns: 'common',
                }),
                onActionButtonClick: goToMarketplace,
            };
        }
        return {
            messageTitle: '',
            messageBody: '',
            BodyImgIcon: null,
            actionButtonTitle: '',
        };
    }, [profileType, isMyProfile, haveExchanges]);
    return (_jsx(EmptyViewInteractive, { className: className, messageTitle: messageTitle, messageBody: messageBody, actionButtonTitle: actionButtonTitle, BodyImgComponent: (BodyImgIcon !== null ? (_jsx(Icon, { size: 200, keepOriginalColor: true, IconComponent: BodyImgIcon })) : null), onActionButtonClick: onActionButtonClick }));
};
export default PortfoliosEmptyView;
